.form-wrapper-contact {
  max-width: 900px;
  margin: 2em auto;

  form {
    // border: 2px solid black;
    // border-radius: 1em;
    textarea, input {
      margin-top: 0.5em;
      display: block;
      min-width: 15em;
    }

    h2 {
      font-size: 1.5em;
      margin: 1em 0;
      font-weight: 600;
    }
    padding: 1em;
    color: inherit;

    p {
      // font-family: Helvetica;
    }
    button {
      background: rgb(186, 20, 20);
      color: white;
      width: 5em;
    }
  }
}
