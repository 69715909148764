.info-smart-hours-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    .info-smart-hours {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        
    }
    .schedule-block-wrapper {
        background: #f8f8f8;
        border-radius: 0.5em;
        padding: 0.5em;
        margin: 0.5em;
    }
    .hours-title {
        font-size: 1.5em;
        font-weight: 600;
        text-transform: uppercase;
        padding: 1em 0.5em 0.5em 0.5em;
    }
    h3 {
        text-align: center;
        padding: 0.5em 0;
        // border-bottom: 1px solid red;
    }
    .day-row {
        display: flex;
        .day-label {
            min-width: 2.5em;
            // text-align: center;
        }
        &:before {
          content: "·";
        //   content: "➤";
          transform: scale(1);
          visibility: hidden;
          padding-right: 0.2em;
          font-weight: 600;
        }
        &.today {
            // font-weight: 600;
            // color: #e20a02;
            // background: blue;
            &:before {
                
                visibility: visible;
              }
        }
    }
}