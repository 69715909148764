@import url('https://fonts.googleapis.com/css?family=Spicy+Rice');
$paddingY: 0.7em;
$borderWidth: 2px;

.coralcafe {
  .cart.minimized {
    background: #fefafa;
  }
  a.checkout-button.button {
    background: #d47228;
    font-weight: 600;
    box-shadow: none;
    border: 2px solid #5a7e2a;
  }


  .category-name .text {
    font-family: 'Spicy Rice', cursive;
    font-weight: 400;
    font-size: 1.5em;
    background: #fefafa;
    border: 4px solid #5a7e2a;
    border-radius: 1em;
    color: #d47228;
  }
  .category-name .text::before {
    content: "❦ "
  }



  .john {
    line-height: 1.3em;
    padding: 1em 0;
    margin: 0;
    display: block;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-family: Georgia, 'Times New Roman', Times, serif;
    .text {
      padding: 0.5em;
      max-width: 400px;
      p {
        margin-bottom: 1em;
      }
    }
    .picture {
      width: 400px;
      img {
        width: 100%;
      }
    }
    /* max-height: 50vh; */
  }
  .order-benefits {
    /* color: white; */
    /* text-shadow: 0px 0px 4px rgba(255,255,255,1); */
    padding: 1em 0;
    margin: 0;
    display: block;
    min-height: 200px;
    /* max-height: 50vh; */
    background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/v1541195042/danstuff/coral-cafe-burbank-6684978.jpg);
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .order-benefits .text {
    color: white;
    background: rgba(0,0,0,0.5);
    padding: 0.5em 0.8em;
  }
  .pages-nav {
    font-size: 0.5em;

    // max-width: $max-site-width;
    margin: 0 auto;

    // font-family: $sigFont;
    // font-style: italic;
    background: white;
    // text-transform: uppercase;
    // font-weight: 800;

    display: flex;
    flex-wrap: warp;
    align-items: center;
    .react-toggle {
      margin-right: 0.5em;
    }
    ul {
      padding: 0.5em 0;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      align-items: stretch;
    }
    @media screen and (max-width: 480px) {
      font-size: 4vw;
    }
  }
  a.page-link {

    text-decoration: none;
    color: black;
    display: block;
    text-transform: uppercase;
    font-weight: 700;
    margin-left: 0.1em;
    // text-transform: lowercase;
    // font-size: 0.8em;
    line-height: 0.7em;
    // letter-spacing: -0.09em;
    // padding: $paddingY 0.3em;
    padding: $paddingY 0.2em calc(#{$paddingY} - #{$borderWidth}) 0.2em;
    // margin: 0.4em 0.05em;
    // padding: 0.2em 0.2em;
    // box-sizing: border-box;
    // border: $borderWidth solid $white;
    // border-top: none;
    // border-bottom: none;
    border-radius: 0.2em;

    &:hover, &.active {
      color: white;
      background: black;
      // border: $borderWidth solid white;
      // border-top: none;
      // border-bottom: none;

    }
  }
}
